@import '~antd/dist/antd.css';
@import './common.less';

::-webkit-scrollbar {
    // display: none;
}


.ant-layout-header,
.ant-layout {
    background-color: white !important;
}

.logo2 {
    width          : 110px;
    height         : 110px;
    background     : url("@{myAliCould}/logo2.png") no-repeat center;
    background-size: contain;
}

.footerColumn {
    height         : 100px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    margin         : 0 auto;
}

.itemCenter {
    align-items: center;
}

.textAlignLeft {
    align-items: flex-start;
}

.wetChartIcon {
    width           : 71px;
    height          : 71px;
    background-color: antiquewhite;
}

.VDivider {
    background-color: black;
    height          : 101px;
    width           : 1px;
}

.projectIcon {
    width           : 35px;
    height          : 35px;
    background-color: antiquewhite;
}

.proIcon {
    width           : 50px;
    height          : 50px;
    background-color: antiquewhite;
}

.proName {
    font-size  : 20px;
    font-family: "NotoSansHans-Bold"
}
